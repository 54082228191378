html {
  box-sizing: border-box;
  font-family: Ubuntu, sans-serif;
  font-size: 10px;
}

body {
  background-color: #faf9f8;
}
/*# sourceMappingURL=index.63f40c3c.css.map */
